<template>
  <div>
    <a-button
      class="float-right mb-3"
      @click="$router.push({name: 'add-my-ocn'})"
    >
      Add New OCN
    </a-button>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th v-if="isAdmin">
            Company Name
          </th>
          <th>OCN Number</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!arrayList.length">
          <td
            style="text-align: center"
            :colspan="3"
          >
            Not Found
          </td>
        </tr>
        <tr
          v-for="value in arrayList "
          v-else
          :key="value"
        >
          <td v-if="isAdmin" />
          <td />
          <td />
          <td>
            <a-tooltip
              placement="top"
              class="pr-0"
            >
              <template #title>
                Remove
              </template>
              <a
                class="a_action_icon bg-secondary mb-2 mr-2"
              >
                <i
                  style="font-size: 16px;"
                  class="fa fa-trash"
                  aria-hidden="true"
                />
              </a>
            </a-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "MyOcn",
  data:() =>({
    isAdmin: JSON.parse(localStorage.is_admin),
    arrayList:[],
  }),
  mounted() {
    if(!this.arrayList.length){
      this.$router.push({name: 'add-my-ocn'})
    }
  }


}
</script>

<style scoped>

</style>